<template>
    <el-main>
        <el-form label-width="200px" :model="form" :rules="rules" ref="form">
            <el-form-item label="类别名：" prop="classify_id">
                <el-cascader v-model="form.classify_id" :options="type_list" placeholder="请选择类别名称" size="small" filterable
                    :show-all-levels="false"
                    :props="{ checkStrictly: true, value: 'id', label: 'name', children: 'data', emitPath: false }"></el-cascader>
                <span class="tips">
                    暂未类别，请
                    <el-link type="primary" @click="$router.push('/gateway/service/serviceType')">添加类别</el-link>
                </span>
            </el-form-item>
            <el-form-item label="牌号名：" prop="brand_name">
                <el-input size="small" placeholder="请输入牌号名" v-model="form.brand_name"></el-input>
            </el-form-item>
            <el-form-item label="厂家名：" prop="supplier">
                <el-input size="small" placeholder="请输入厂家名" v-model="form.supplier"></el-input>
            </el-form-item>
            <el-form-item label="价格类型：" prop="price_type">
                <el-select v-model="form.price_type" placeholder="请选择类别名称" size="small" filterable>
                    <el-option label="市场价" value="1"></el-option>
                    <el-option label="出场价" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="地区：" prop="area_name">
                <el-input size="small" placeholder="请输入地区" v-model="form.area_name"></el-input>
            </el-form-item>
            <!--<el-form-item label="地区：" prop="province_id">
        <el-select v-model="form.province_id" placeholder="请选择类别名称" size="small" filterable clearable>
          <el-option :label="item.name" :value="item.id" v-for="item in area_list" :key="item.id"></el-option>
        </el-select>
      </el-form-item>-->
            <el-form-item label="时价价格：" prop="price">
                <el-input size="small" placeholder="请输入时价价格" v-model="form.price"></el-input>
                <span class="tips">元/吨</span>
            </el-form-item>
        </el-form>
        <Preservation @preservation="save"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import { inspectPrice } from "@/util/verification"
export default {
    components: {
        Preservation,
    },
    data () {
        return {
            type_list: [],
            form: {
                classify_id: '',
                brand_name: '',
                supplier: '',
                price_type: '1',
                area_name: '',
                price: '',
            },
            rules: {
                classify_id: [{ required: true, message: '请选择类别名', trigger: 'change' }],
                brand_name: [{ required: true, message: '请输入牌号名', trigger: 'blur' }],
                price: [{ required: true, message: '请输入时价价格', trigger: 'blur' },
                {
                    validator: (rule, value, callback) => {
                        if (!inspectPrice(value)) {
                            callback(new Error('请输入价格正确格式'));
                        } else {
                            callback();
                        }
                    }, trigger: 'blur'
                }],
            },
            area_list: [],
        };
    },
    created () {
        this.getType();
        //this.getArea();
    },
    methods: {
        getType () {
            this.$axios.post(this.$api.gateway.currentprice.setInfo).then(res => {
                if (res.code == 0) {
                    this.type_list = res.result.subscription_prices;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getArea () {
            this.$axios.get(this.$api.set.area, { pid: 0 })
                .then(res => {
                    if (res.code == 0) {
                        this.area_list = res.result.list;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        save () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$axios.post(this.$api.gateway.currentprice.add, this.form)
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('添加成功')
                                this.$router.go(-1)
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    min-height: 100%;

    .tips {
        color: #888888;
        margin-left: 20px;
        line-height: 20px;
    }

    .el-input,
    .el-cascader,
    .el-select {
        width: 600px;
    }
}
</style>
